<template>
  <div class="n3 outItem">
    <div class="label">{{groupTypeList[12][getLocal()]}}</div>
    <div class="content unknow">
      <div :class="['item', { low: leftLow }]">
        <div class="name">{{ data.tms[0] ? data.tms[0].na : "?" }}</div>

        <img
          v-if="data.tms[0] && data.tms[0].lurl"
          class="hasTms"
          :src="data.tms[0].lurl"
          alt=""
        />

        <img
          v-else
          :src="require('@/assets/imgs/comm/outDefault.svg')"
          alt=""
        />
      </div>
      <div class="center">
        <div v-if="data.st != 0 && data.st != 5" class="vs">VS</div>
        <div :class="['scoreRow']" v-html="centerFn"></div>
        <!-- <div class="etRow" v-if="ET && getOvertime.length">
          <span v-for="(item, key) in getOvertime" :key="key">
            {{ sgsPeList[item.pe][getLocal()] + item.sc[0] + "-" + item.sc[1] }}
          </span>
        </div> -->
      </div>
      <div :class="['item', 'rightItem', { low: rightLow }]">
        <img
          v-if="data.tms[1] && data.tms[1].lurl"
          class="hasTms"
          :src="data.tms[1].lurl"
          alt=""
        />

        <img
          v-else
          :src="require('@/assets/imgs/comm/outDefault.svg')"
          alt=""
        />
        <div class="name">{{ data.tms[1] ? data.tms[1].na : "?" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Format } from "@/utils/fn";

import { groupTypeList } from "@/utils/filedList";
import { sgsPeList } from "@/utils/filedList";

export default {
  name: "OutLastItem",
  props: ["data", "ET"],
  data(){
    return {
      groupTypeList,
      sgsPeList
    }
  },
  methods: {
    getTygData(data) {
      return (data.find((item) => item.tyg == 5) || {}).sc || [0, 0];
    },
  },
  computed: {
    getOvertime() {
      if (this.data.sgs) {
        return this.data.sgs.filter((item) => {
          return (
            (item.pe == 1006 && item.tyg == 5) ||
            (item.pe == 1013 && item.tyg == 5)
          );
        });
      }
      return [];
    },
    centerFn() {
      switch (this.data.st) {
        case 0:
          return `<span class="${this.leftLow ? "low" : ""}">${
            this.scoreData[0]
          }</span>-<span class="${this.rightLow ? "low" : ""}">${
            this.scoreData[1]
          }</span>`;
        case 5:
          return `<span class="${this.leftLow ? "low" : ""}">${
            this.scoreData[0]
          }</span>-<span class="${this.rightLow ? "low" : ""}">${
            this.scoreData[1]
          }</span>`;
      }
      return Format(new Date(this.data.btm), "MM/dd");
    },
    leftLow() {
      if (this.data.st == 4 || this.data.st === undefined) {
        return false;
      }
      return this.scoreData[0] < this.scoreData[1];
    },
    rightLow() {
      if (this.data.st == 4 || this.data.st === undefined) {
        return false;
      }
      return this.scoreData[0] > this.scoreData[1];
    },
    scoreData() {
      let scoreData = (
        (this.data.sgs || []).find((item) => item.tyg == 5) || {}
      ).sc || [0, 0];
      return (
        this.data.sgs?.reduce(
          (prev, item) => {
            if ((item.pe == 1006 || item.pe == 1013) && item.tyg == 5) {
              prev = prev.length > 0 ? prev : [0, 0];
              prev[0] = prev[0] + item.sc[0];
              prev[1] = prev[1] + item.sc[1];
            }
            return prev;
          },
          [...scoreData]
        ) || scoreData
      );
    },
  },
};
</script>
<style lang="scss">
.outItem {
  .low {
    opacity: 0.5;
  }
}
</style>
<style lang="scss" scoped>
.outItem {
  .label{
    display: inline-block;
    background: #131A3E;
    height: 20px;
    line-height: 20px;
    padding: 0px 6px;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 10px;
    color: #FFF;
  }
  .content {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .name {
      margin: 4px 0;
      text-align: center;
    }
    .center {
      padding: 0 3px;
      line-height: initial;
      text-align: center;
      .vs {
        font-family: "DIN Medium";

        color: #5A6375;
        font-size: 18px;
        text-align: center;
      }
      .scoreRow {
        font-family: "DIN Medium";
        color: $text-color;
        font-size: 14px;
        font-weight: 700;
      }
      .etRow {
        color: #5a6375;
        font-size: 12px;
      }
    }
    .item {
      display: flex;
      align-items: center;
      img {
        vertical-align: top;
        margin-left: 10px;
      }
    }
    .rightItem {
      img {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
  .unknow {
    img{
      width: 48px;
      height: 48px;
    }
  }
  .line {
    display: flex;
    flex-wrap: wrap;
    div {
      width: 50%;
      height: 6px;
      &:nth-child(1) {
        border-left: 2px solid $out-line;
        border-bottom: 2px solid $out-line;
      }
      &:nth-child(2) {
        border-right: 2px solid $out-line;
        border-bottom: 2px solid $out-line;
      }
      &:nth-child(3) {
        border-right: 2px solid $out-line;
      }
    }
  }
  .bottomLine {
    display: flex;
    flex-wrap: wrap;
    div {
      width: 50%;
      height: 6px;
      &:nth-child(3) {
        border-left: 2px solid $out-line;
        border-top: 2px solid $out-line;
      }
      &:nth-child(4) {
        border-right: 2px solid $out-line;
        border-top: 2px solid $out-line;
      }
      &:nth-child(1) {
        border-right: 2px solid $out-line;
      }
    }
  }
}

.n3 {
  width: 270px;
  text-align: center;
}
.dark{
  .outItem .content .center .vs{
    color: #D2D4D9;
  }
}
</style>
